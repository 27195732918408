import styled from 'styled-components';

const Right = styled.div`
  @media (min-width: ${props => props.theme.responsive.medium}) {
    float: left;
    display: block;
    margin-right: 0;
    width: 50%;
  }
  @media (max-width: ${props => props.theme.responsive.medium}) {
    margin-top: 5vw;
  }
  ul {
    display: grid;
    grid-template-columns: 1fr;
    @media (max-width: ${props => props.theme.responsive.small}) {
      grid-template-columns: 1fr;
    }
    li {
      display: grid;
      grid-template-columns: 60px auto;
      justify-content: flex-start;
      align-items: center;
      padding: 0.8em 1em;
      border-bottom: 1px solid ${props => props.theme.colors.misc.border};
      &:last-child {
        border-bottom: none;
      }
      img {
        width: 30px;
        height: 30px;
        filter: contrast(1000%) invert(100%) sepia(100%) saturate(10500%)
          hue-rotate(320deg);
      }
      h4 {
        margin: 5px 0 0;
        font-size: 0.8em;
        letter-spacing: 0.025em;
        text-transform: uppercase;
        line-height: 1.35;
        font-family: ${props => props.theme.fonts.body};
        color: ${props => props.theme.colors.text.dark};
      }
    }
  }
`;

export default Right;
