import React from 'react';
import moment from 'moment';

// Components
import { Wrapper, Inner } from './files';

const FeaturesConditions = ({
  rates,
  startDate,
  endDate,
  expiryDate,
  terms
}) => (
  <Wrapper>
    <Inner>
      <h3>Information</h3>
      <ul>
        <li>
          <span>Special Rate</span>
          <span>{rates}</span>
        </li>
      </ul>
      <h3>Dates</h3>
      <ul>
        <li>
          <span>Booking Period</span>
          <span>
            {expiryDate !== null
              ? `Until ` + moment(expiryDate).format('MMMM Do YYYY')
              : 'This special promotion does not have an expiry date.'}
          </span>
        </li>
        <li>
          <span>Stay Period</span>
          <span>
            {startDate !== null && endDate !== null
              ? `From ` +
                moment(startDate).format('MMMM Do YYYY') +
                ` to ` +
                moment(endDate).format('MMMM Do YYYY')
              : 'This special promotion does not have restrictions on the period of your stay.'}
          </span>
        </li>
      </ul>
      <h3>Terms</h3>
      <ul>
        <li>
          <div
            dangerouslySetInnerHTML={{
              __html: terms.childMarkdownRemark.html
            }}
          />
        </li>
      </ul>
    </Inner>
  </Wrapper>
);

export default FeaturesConditions;
