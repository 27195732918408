import styled from 'styled-components';

const Inner = styled.div`
  position: relative;
  display: block;
  max-width: 1000px;
  width: 100%;
  padding: 0 32px;
  margin: 0 auto;
  ul {
    margin-bottom: 4rem;
    &:last-child {
      margin-bottom: 0;
    }
    li {
      display: flex;
      justify-content: flex-start;
      padding: 0.9em 0;
      border-top: 1px solid ${props => props.theme.colors.misc.border};
      &:last-child {
        border-bottom: 1px solid ${props => props.theme.colors.misc.border};
      }
      p {
        margin-bottom: 0;
      }
      span {
        margin: 3px 0 0;
        &:nth-child(1) {
          padding-right: 10px;
          width: 25%;
        }
        &:nth-child(2n) {
          width: 75%;
        }
      }
    }
  }
`;

export default Inner;
