import React from 'react';
import { graphql } from 'gatsby';

// Components
import SeoDetails from '../components/common/seoDetails';
import HeroImage from '../components/common/sections/heroImage';
import FeaturesSpecials from '../components/common/sections/featuresSpecials';
import FeaturesConditions from '../components/common/sections/featuresConditions';
import CarouselSimilar from '../components/common/sections/carouselSimilar';

const SpecialPage = ({ data }) => {
  const {
    id,
    seoContent,
    subtitle,
    includes,
    rates,
    startDate,
    endDate,
    expiryDate,
    terms,
    promoCode
  } = data.contentfulSpecials;
  const specialData = data.allContentfulSpecials.edges;
  const filterSpecials = specialData.filter(({ node }) => node.id !== id);

  return (
    <>
      <SeoDetails seoContent={seoContent} />
      <HeroImage seoContent={seoContent} />
      <FeaturesSpecials
        seoContent={seoContent}
        subtitle={subtitle}
        includes={includes}
        promoCode={promoCode}
      />
      <FeaturesConditions
        rates={rates}
        terms={terms}
        startDate={startDate}
        endDate={endDate}
        expiryDate={expiryDate}
      />
      <CarouselSimilar itemData={filterSpecials} />
    </>
  );
};

export const query = graphql`
  query specialQuery($slug: String!) {
    contentfulSpecials(seoContent: { slug: { eq: $slug } }) {
      id
      title
      subtitle
      seoContent {
        pageTitle
        menuTitle
        slug
        description {
          description
          childMarkdownRemark {
            html
          }
        }
        featuredImage {
          title
          fluid(maxWidth: 2000) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
      }
      includes
      rates
      terms {
        childMarkdownRemark {
          html
        }
      }
      startDate
      endDate
      expiryDate
      promoCode
    }
    allContentfulSpecials {
      edges {
        node {
          id
          title
          rates
          seoContent {
            menuTitle
            slug
            description {
              description
            }
            featuredImage {
              title
              fluid(maxWidth: 2000) {
                ...GatsbyContentfulFluid_withWebp
              }
            }
          }
          expiryDate
        }
      }
    }
  }
`;

export default SpecialPage;
